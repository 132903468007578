@import '../../global.sass'
  
.card
  position: relative
  transition-duration: 200ms
  &:hover
    transform: scale(1.05)
    div
      text-decoration: underline
  div
    display: flex
    justify-content: center
    align-items: center
    color: white
    background-color: transparentize($primary, 0.5)
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    padding: 10px
    color: white
    border-radius: 10px
  img
    max-width: 300px
    display: block
    mix-blend-mode: multiply