.landing
  width: 100%
  height: 50vh
  display: flex
  justify-content: center
  align-items: center
  margin-top: 70px
  background: url("/assets/images/bg-piecz.webp") center no-repeat
  background-size: cover
  img
    width: 50%
    max-width: 300px