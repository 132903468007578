@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@400;700&family=Montserrat:wght@500;900&family=Red+Hat+Display:wght@400;700&display=swap')
  
// COLORS
$primary: #010C17
$secondary: #010C17

// FONTS
$h1-normal: 64px
$h1-medium: 48px
$h1-small: 32px

$h2-normal: 48px
$h2-medium: 36px
$h2-small: 30px

$p-normal: 20px
$p-medium: 18px
$p-small: 16px

// MEDIA
$media-normal: 1100px
$media-medium: 900px
$media-small: 600px
  
*, *::before, *::after
  box-sizing: border-box
  font-family: 'Red Hat Display', 'Roboto', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

body, html
  margin: 0
  padding: 0

.margin-x
  margin: 0 150px
  @media screen and (max-width: $media-normal)
    margin: 0 50px
  @media screen and (max-width: $media-medium)
    margin: 0 20px

.padding-x
  padding: 0 150px
  @media screen and (max-width: $media-normal)
    padding: 0 50px
  @media screen and (max-width: $media-medium)
    padding: 0 20px

a
  cursor: pointer