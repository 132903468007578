@import '../../global.sass'

.footer
  @extend .padding-x
  padding-top: 30px
  padding-bottom: 30px
  width: 100%
  background-color: $primary
  color: white
  a
    color: white
    text-decoration: none
    &:hover
      text-decoration: underline
  .grid
    display: grid
    grid-template-columns: 1fr 1fr
    @media screen and (max-width: $media-medium)
      grid-template-columns: 1fr
    gap: 30px
    &__row
      display: flex
      gap: 10px
      width: 100%
      &--right
        @media screen and (max-width: $media-medium)
          justify-content: flex-end
    .icon
      width: 36px
  .our-brands
    width: 100%
    display: grid
    grid-template-columns: repeat(7, 1fr)
    @media screen and (max-width: $media-medium)
      grid-template-columns: repeat(4, 1fr)
    @media screen and (max-width: $media-small)
      grid-template-columns: repeat(2, 1fr)
    justify-items: center
    align-items: center
    gap: 20px
    margin:
      top: 30px
      bottom: 30px
    img
      max-width: 90px
  .map
    display: block
    height: 120px
    @media screen and (max-width: $media-medium)
      height: auto
      width: 100%
  .call-button
    display: block
    background-color: white
    color: $primary
    border: 2px solid $primary
    padding: 10px 20px
    border-radius: 30px
    font-weight: bold
    font-size: 18px
    transition: all 0.3s ease-in-out
    cursor: pointer
    a
      color: $primary
      text-decoration: none